.character-card {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid #97a3b6;

  &-selected,
  &:hover {
    background-color: #f0f0f0;
  }

  &:last-child {
    border: none;
  }

  &__checkbox {
    margin: 0 10px 0 0;
  }

  &__image {
    width: 35px;
    height: 35px;
    border-radius: 6px;
    margin-right: 10px;
  }

  &__content {
    display: flex;
    flex-direction: column;

    &__title {
      margin-bottom: 5px;
    }

    &__episode-count {
      margin-bottom: 5px;
    }
  }
}
