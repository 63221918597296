.multiselect {
  position: relative;
  width: 400px;
  margin-top: 200px;

  &__selected-items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #97a3b6;
    border-radius: 12px;
    min-height: 30px;
    padding: 2px 33px 2px 2px;
    margin-bottom: 10px;
  }

  &__selected-item {
    display: flex;
    align-items: center;
    background-color: #e2e8f0;
    border-radius: 12px;
    color: #334155;
    padding: 5px;
    margin: 3px;
  }

  &__character-list {
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 425px;
    overflow-y: auto;
    border: 1px solid #97a3b6;
    z-index: 1;
    border-radius: 12px;

    &__empty-state {
      text-align: center;
      padding: 20px;
    }
  }

  &__remove-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
    background: #94a3b8;
    color: #fff;
    fill: #fff;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    z-index: 1;
  }

  &__search-container {
    display: inline-block;
    padding: 5px;

    input {
      border: none;
      outline: none;
      height: 30px;
      width: 0;
      max-width: 350px;
      min-width: 70px;
    }
  }

  &__down-icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    font-size: 20px;
    color: #475569;
  }
}
